import Vue from 'vue'
import Vuex from 'vuex'

import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pkh: "",
    connected: false,
    loading: false,
    activeMemo: [],
    doneMemo: [],
  },
  getters,
  mutations,
  actions,
});

/*
import { createStore } from "vuex";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default createStore({
  state: {
    pkh: "",
    connected: false,
    loading: false,
    activeMemo: [],
    doneMemo: [],
  },
  getters,
  mutations,
  actions,
});
xxxxxxxxxxxxxxxxxxxxxxxxxx
import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './authentication'
import app from './app'
import products from './products'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    authentication,
    app,
    products
  }
})
*/
