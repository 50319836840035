import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'

//  router
 Vue.use(VueRouter)
 // ELSE?! kann routes raus?
 const router = new VueRouter({ mode: 'history',
  routes: [
    { path: '/', component: App },
    { path: '/about', component: App },
    { path: '/contact', component: App },
    { path: '/explore', component: App }
  ]
});

Vue.config.devtools = false
Vue.config.productionTip = false

if (module.hot) {
  module.hot.accept() // already had this init code

  module.hot.addStatusHandler((status) => {
    if (status === 'prepare') console.clear()
  })
}

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
