<template>
  <v-app id="inspire" >



<!-- #showframe -->
 <template v-if="this.$route.path !=='/' && this.$route.path !== '/frames' && this.$route.path !=='/explore'  && this.$route.path !== '/about'  && this.$route.path !== '/home' ">


<!--
  && starts with tz || min 1 char?
= is showframe
-->

<v-toolbar v-if="!loadedEntries" color="black">
      <v-toolbar-title class="grey--text">DIYFRAME.XYZ <small>by Andre Fuchs</small></v-toolbar-title>
       </v-toolbar >

<!--  @touchstart="mouseMove()" @touchmove="mouseMove() -->
  <div @mousemove="mouseMove()">


      <v-container
       @touchmove="mouseMove()"
        height="100vh"
        width="100vh"
        fluid
        class="d-flex black align-center justify-center ma-0 pa-0"
      >
      <v-card v-if="!loadedEntries"   dark color="black" width="600">
  
<v-card
           color="black"
            dark
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
             
             
             <v-card-title class="text-h2 grey--text text--lighten-2">{{ formatPkhString(urlUser) }}</v-card-title>

<v-card-title class="text-h3 grey--text text--lighten-2">FRAME {{urlFrame}}</v-card-title>

          <v-card-title class="text-h4 grey--text">LOADING... {{getActiveMemo[0].memo}} </v-card-title>

  <v-card-text >
          <v-progress-circular
       v-if="!loadedEntries"
      indeterminate
      color="grey"
    ></v-progress-circular>


 {{ convertIDframe(getActiveMemo[0].memo) }}

     </v-card-text>


              </div>

<!-- 
  ENDE vllt
              <v-avatar
              v-show="false"
                class="ma-3"
                size="125"
                              >
                <v-img src="https://designerwhocodes.de/avatar.png"></v-img>
              </v-avatar>
              -->
            </div>

          </v-card>
  </v-card>

        <!-- VIDEO  add controls :options="options -->
        <v-card
          v-if="loadedEntries && mimeType === 'video'"
          flat
          tile
          class="mx-auto black align-center justify-center ma-0 pa-0"
          height="100vh"
          width="100vh"
        >
         
          <video
            id="video"
            ref="video"
            autoplay
            muted
            height="100%"
            width="100%"
            loop
            :src="filePath"
            @onload="hideLoading()"
            @error="videoError()"
            @loadstart="showLoading()"
          ></video>
        </v-card>

        <!-- #img -->
        <v-img
          v-if="loadedEntries && mimeType === 'image'"
          lazy-src="https://static.artusnft.com/loading.png"
          height="100vh"
          width="100vh"
          contain
          :src="filePath"
          @error="imageError()"
          @loadstart="showLoading()"
          @load="hideLoading()"
        >
        </v-img>

        <!--  mimeType === null || mimeType === 'XXXXXXXXXXXXXxvideo' -->
        <v-card
          v-if="loadedEntries && mimeType === 'application'"
          flat
          tile
          class="mx-auto black align-center justify-center ma-0 pa-0"
          height="100vh"
          width="100%"
        >
          <!-- #iframe onload="hideLoading()" -->
          <iframe
            id="interactiveX"
            loading="lazy"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            allowTransparency="false"
            :src="filePath"
            @error="iframeError()"
            @loadstart="showLoading()"
            @load="hideLoading()"
          ></iframe>
        </v-card>
      
      </v-container>

      <v-btn
        v-if="mouseMoving"
        color="white"
        class="black--text"
        large
        fixed
        bottom
        left
        @click="toggleFullScreen()"
      >
        <img height="33" class="mr-4" src="@/assets/fullscreen.svg" />
        Fullscreen</v-btn
      >
      <v-btn
        v-show="loadingInfo"
        color="black"
        fab
        dark
        small
        fixed
        bottom
        right
      >
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        ></v-progress-circular>
      </v-btn>
        </div>

 </template>
<!-- #showframe -->

<!-- 
<template v-if="this.$route.path ==='/' && this.$route.path === '/frames' && this.$route.path ==='explore'  && this.$route.path === 'about'  && this.$route.path === 'home' ">
  -->
  
  <template v-else>
   <v-system-bar app class="white--text orange darken-4">
            <span class="font-weight-bold" @click="openLink('https://designerwhocodes.de/')">DIYFRAME by Andre Fuchs | 👻 GHOSTNET</span> 
            <v-spacer></v-spacer>
<span class="font-weight-bold">ALPHA 0.0.1</span>
                  </v-system-bar>

    <v-app-bar
      app
      clipped-right
      flat
      height="72"
      color="black"
      dark
    >
         <!--
             <v-btn
              icon
              >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            -->
<v-app-bar-nav-icon @click="drawer =! drawer"></v-app-bar-nav-icon>
          <v-toolbar-title v-if="this.$route.path==='/'">HOME</v-toolbar-title>
           <v-toolbar-title v-else-if="this.$route.path==='/frames'">FRAMES</v-toolbar-title>
          <v-toolbar-title v-else-if="this.$route.path==='/explore'">EXPLORE</v-toolbar-title>
          <v-toolbar-title v-else-if="this.$route.path==='/about'">ABOUT</v-toolbar-title>

 <v-spacer></v-spacer>
 <!--
 <v-progress-circular
     v-if="getLoading"
      indeterminate
      color="grey"
    ></v-progress-circular>
-->
  <v-btn depressed dark  v-if="!getConnected" @click="connect"><v-icon left>mdi-login-variant</v-icon> Connect Wallet</v-btn>

  <v-menu v-if="getConnected" offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn  icon class="elevation-2" v-on="on">
        <v-badge
          color="success"
          dot
            :content="messages"
         :value="messages"
          bordered
          offset-x="10"
          offset-y="10"
        >
          <v-avatar size="40">
            <v-img src="https://designerwhocodes.de/avatar.png"></v-img>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <v-list nav>
      <v-list-item
        v-for="(item, index) in menuUser"
        :key="index"
        :exact="item.exact"
        :disabled="item.disabled"
        link
      >

        <v-list-item-icon>
          <v-icon small :class="{ 'grey--text': item.disabled }">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content class="grey--text text--darken-2">
          <v-list-item-title >{{ formatAddress(getPkh) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

  <v-divider class="my-1"></v-divider>

      <v-list-item link @click="logoutUser">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
  

  
    </v-app-bar>



    <v-navigation-drawer
      v-model="drawer"
      app
      width="200"
       fixed
     
      color="black"
      dark
    >
    <!--
      DISCORD ISH
      
      <v-navigation-drawer
        v-model="drawer"
        absolute
        color="grey lighten-3"
        mini-variant
      >
        <v-avatar
          class="d-block text-center mx-auto mt-4"
          color="grey darken-1"
          size="36"
        ></v-avatar>

        <v-divider class="mx-3 my-5"></v-divider>

        <v-avatar
          v-for="n in 6"
          :key="n"
          class="d-block text-center mx-auto mb-9"
          color="grey lighten-1"
          size="28"
        ></v-avatar>
      </v-navigation-drawer>
-->
      <v-sheet
        color="black darken-4"
        height="128"
        width="100%"
      >
      <v-img src="@/assets/v-logo.png" class="mt-2" contain height="90"></v-img>
      </v-sheet>

     <v-list  nav >
        <v-list-item @click="changeURL('HOME')" class="grey--text hidden-sm-and-down" link to="/">
          <v-list-item-icon>
            <v-icon color="grey darken-2">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>HOME</v-list-item-title>
        </v-list-item>
        <v-list-item @click="changeURL('FRAMES | ' + getActiveMemo.length)" class="grey--text hidden-sm-and-down" link to="/frames">
          <v-list-item-icon>
           <v-icon color="grey darken-2">mdi-image-filter-none</v-icon>
          </v-list-item-icon>
          <v-list-item-title>FRAMES</v-list-item-title>
        </v-list-item>
        <v-list-item @click="changeURL('EXPLORE')" class="grey--text hidden-sm-and-down" link to="/explore">
          <v-list-item-icon>
           <v-icon color="grey darken-2">mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-title>EXPLORE</v-list-item-title>
        </v-list-item>
            <v-list-item @click="changeURL('ABOUT')" class="grey--text hidden-sm-and-down" link to="/about">
          <v-list-item-icon>
           <v-icon color="grey darken-2">mdi-image-filter-center-focus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>ABOUT</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-navigation-drawer
      app
      clipped
      right
      color="black darken-4"
    >
    <!--
      <v-list>
        <v-list-item
          v-for="n in 3"
          :key="n"
          link
        >
          <v-list-item-content>
            <v-list-item-title class="grey--text">DONATION {{ n }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      -->


   <v-list-item-title class="grey--text text--lighten-1">DONATIONS</v-list-item-title>
     <v-list-item-content class="grey--text text--lighten-1">
      Your support via tez or NFT donations are gladly accepted.
     </v-list-item-content>
  
     <v-btn depressed outlined class="mb-2 "
                  @click="openLink('https://tzkt.io/tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc/operations/')"
                  color="grey lighten-1">
                  diyframe.tez<v-icon right>mdi-arrow-top-right</v-icon>
                </v-btn>
<!--
  TODO 
if !delegator 
change to ARTERY


 <v-list-item link>
     <v-list-item-content>
    <v-list-item-title class="grey--text">diyframe.tez</v-list-item-title>
    </v-list-item-content>
    </v-list-item>
-->
    </v-navigation-drawer>

    <v-main class="black">
     
      <!-- #home -->
   <template v-if="this.$route.path==='/'">
 
   
    <v-container class="black" fluid height="100%">
      <v-row dense>
        <v-col
        cols="12"
        >
         <!--  v-if="getActiveMemo.length === 0" -->
           <v-card 
 
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>
  <v-card-title class="noWordBreak"><v-icon left>mdi-wallet</v-icon>SETUP A TEZOS GHOSTNET WALLET</v-card-title>
</v-sheet>

                        <v-card-actions class="black" >
              <v-btn dark  @click="openLink('https://blog.diyframe.xyz/setup-a-tezos-ghostnet-wallet')" depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
          </v-card>

  <v-col
        cols="12"
        >
          <v-card 
              class="mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
          >
     
<v-sheet
dark
  color="black"

>
              <v-card-title class="noWordBreak"><v-icon left>mdi-image-filter-none</v-icon> CREATE YOUR FIRST DIYFRAME FRAME</v-card-title>
</v-sheet>

            <v-card-actions class="black">
              <v-btn @click="openLink('https://blog.diyframe.xyz/create-your-first-diyframe/')" dark depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

          <v-card 
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>
  <v-card-title class="noWordBreak"><v-icon left>mdi-devices</v-icon>SETUP A DEVICE AND DISPLAY FOR DIYFRAME</v-card-title>
 <!-- <v-card-subtitle>TUTORIAL</v-card-subtitle> -->
</v-sheet>

            <v-card-actions class="black" >
              <v-btn dark @click="openLink('https://blog.diyframe.xyz/setup-a-device-and-display-for-diyframe/')" depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

       

   <v-col
        cols="12"
        >
          <v-card 
             class="mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
          >
          <v-sheet
          dark 
  color="black"
>
                   <v-card-title  class="noWordBreak"><v-icon left>mdi-image-filter-center-focus</v-icon> NEED HELP? CONTACT ANDRE FUCHS</v-card-title>
</v-sheet>

            <v-card-actions class="black">
              <v-btn @click="openLink('https://twitter.com/AndreFvchs')" dark depressed block outlined>
                <v-icon left>mdi-twitter</v-icon>CONTACT
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
 
  </template>



<!-- #showframe -->
 <template v-if="this.$route.path !=='/' && this.$route.path !== '/frames' && this.$route.path !=='/explore' && this.$route.path !== '/about' && this.$route.path !== '/home' ">



&& starts with tz || min 1 char?
<!--
= is showframe
-->
 </template>
<!-- #showframe -->

<!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->

<!-- #about -->
   <template v-if="this.$route.path==='/about'">

    <v-container class="black" fluid height="100%">
      <v-row dense>
        <v-col
        cols="12"
        >

  <v-card
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
   
  >
    <v-sheet
dark
  color="black"

>
    <v-list-item three-line>
      <v-list-item-content>
     
        <v-list-item-title class="text-h5 mb-1">
         DONATE
        </v-list-item-title>
        <v-list-item-subtitle class="white--text mb-4">Your support via tez or NFT donations are gladly accepted.</v-list-item-subtitle>
  
<v-tooltip v-model="showTooltip" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed outlined class="subtitle-2 mb-2 custom-transform-class text-none" color="white"
                      @click="copyToClipboard('tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc')">
                      tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc
                      <v-icon right     v-bind="attrs"
              v-on="on">mdi-content-copy</v-icon>
   </v-btn>
                  </template>
                
                  <span>Copied <v-icon left>mdi-clipboard-check-multiple-outline</v-icon> I really appreciate your
                    support. Thank
                    you! <i>Andre</i></span>

                </v-tooltip>


                <v-btn depressed outlined class="subtitle-2 mb-2 custom-transform-class text-none"
                  @click="openLink('https://tzkt.io/tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc/operations/')"
                  color="white">
                  diyframe.tez on tzkt.io <v-icon right>mdi-arrow-top-right</v-icon>
                </v-btn>
      </v-list-item-content>
    
</v-list-item>
</v-sheet>
  </v-card>


 <v-card
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
  >
      <v-sheet
dark
  color="black"
 
>
   <v-list-item three-line>
      <v-list-item-content>
     
        <v-list-item-title class="text-h5 mb-1">
          ABOUT
        </v-list-item-title>
<v-avatar
  size="100"
  color="black"
>
  <img src="https://designerwhocodes.de/avatar.png" alt="">

</v-avatar>

    <v-card-text>
   <div>DIYFRAME is founded and developed by Andre Fuchs. <br/>
   Andre is a senior developer with a diploma in communication design and multimedia.</div>
    </v-card-text>

    <v-card-actions>
            <v-btn
        color="white"
        outlined
    @click="openLink('https://twitter.com/andrefvchs')"
      >
       <v-icon left>mdi-twitter</v-icon> 
      </v-btn>
      <v-btn
        color="white"
        outlined
    @click="openLink('https://designerwhocodes.de/')"
      >
      <v-icon left>mdi-smart-card</v-icon>  WEBSITE
      </v-btn>

    

         <v-btn
        color="white"
        outlined
    @click="openLink('https://www.fxhash.xyz/u/andre')"
      >
       <v-icon left>mdi-application-array-outline</v-icon> FX(HASH)
      </v-btn>
      <!-- objkt https://objkt.com/profile/andrefuchs/created -->
    </v-card-actions>
      </v-list-item-content>
    
</v-list-item>
</v-sheet>
  </v-card>

  <v-card
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
  >
        <v-sheet
dark
  color="black"
 
>
    <v-card-text>
      <div>DIYFRAME by Andre Fuchs, 2022</div>
  
      <p>Version 0.0.1 (ghostnet alpha)</p>
 <p>andre@diyframe.xyz | diyframe.xyz | diyframe.tez</p>
    </v-card-text>
    </v-sheet>
  </v-card>


     </v-col>
              </v-row>
               
                 </v-container>
  </template>


<!-- #frames if else if machen später? -->
  <template v-if="this.$route.path==='/frames'"  >

    <v-container class="black" fluid height="100%">
      <v-row dense>
        <v-col
        cols="12"
        >
<!-- TODO container fehlt  === 0 or null or undefined -->

{{ getActiveMemo.length }}

  <v-card 
  v-if="getActiveMemo.length <= 0 || getActiveMemo.length === undefined "
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>

  <v-card-title class="noWordBreak"><v-icon left>mdi-wallet</v-icon>CONNECT YOUR TEZOS GHOSTNET WALLET TO GET STARTED</v-card-title>
</v-sheet>

            <v-card-actions class="black" >
              <v-btn dark  @click="connect" depressed block outlined>
                <v-icon left>mdi-login-variant</v-icon>Connect Wallet
              </v-btn>
            </v-card-actions>
                        <v-card-actions class="black" >
              <v-btn dark  @click="openLink('https://blog.diyframe.xyz/setup-a-tezos-ghostnet-wallet')" depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
          </v-card>

<!-- #test -->
<!--
 <v-card   
          v-for="(item, index) in arrID"
          :key="index">


 {{ index }} ||| {{ arrID[index].diyframe_frame_id  }} || {{ arrID[index].token_id  }} ||  {{ arrID[index].name  }}   
 </v-card>
-->

<!-- #cm  #current -->
  <v-card
  v-for="(item, index) in arrayMain"
    class=" mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
    :key="index"
   
  >
<v-sheet
dark
  color="black"

>
    <v-card-title>
  FRAME {{ arrayMain[index][0].diyframe_frame_id }}
</v-card-title>
</v-sheet>
<v-divider></v-divider>

<template v-if="Object.entries(arrayMain[index]).length > 1">
<v-card
  v-for="(itemX, indexX) in arrayMain[index]"
    class="mx-auto rounded-0"
    max-width="600"    
    flat
    outlined
    :key="indexX"
   color="black"
   
      >
<v-sheet
dark
  color="black"

>

<v-list-item two-line >

 <v-list-item-avatar tile class="mr-2">
 <v-img :src="httpToIPFS(itemX.thumbnail_uri)"  contain width="50" height="50"></v-img>
 </v-list-item-avatar>

 <v-list-item-content >
 <v-list-item-title><span class="font-weight-bold">{{ itemX.name }}</span> 
 
 • <span v-if="itemX.artist_profile !== null && itemX.artist_profile!== undefined">{{getUsername(itemX.artist_profile.alias,itemX.artist_address) }}</span></v-list-item-title>

  <v-list-item-subtitle>
 <span class="text-capitalize"> {{ itemX.platform }}</span> • <span class="font-weight-bold">{{ itemX.token_id }}</span>
     </v-list-item-subtitle>
 </v-list-item-content>

    </v-list-item>

 <v-divider class=""></v-divider>
 
 </v-sheet>
</v-card>
</template>

<template v-else>
<v-sheet
dark
  color="black"

>
<v-list-item two-line>
  

 <v-list-item-avatar tile class="mr-2">
 <v-img :src="httpToIPFS(item[0].thumbnail_uri)"  contain width="50" height="50"></v-img>
 </v-list-item-avatar>

 <v-list-item-content >
 <v-list-item-title><span class="font-weight-bold">{{ item[0].name }}</span> 
 
 • <span v-if="item[0].artist_profile !== null && item[0].artist_profile!== undefined">{{getUsername(item[0].artist_profile.alias,item[0].artist_address) }}</span></v-list-item-title>

  <v-list-item-subtitle>
 <span class="text-capitalize"> {{ item[0].platform }}</span> • <span class="font-weight-bold">{{ item[0].token_id }}</span>
     </v-list-item-subtitle>
 </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    </v-sheet>
         </template>

  <v-card-actions class="black">
         <v-btn @click.native="dialogEdit = true, activeFrameID = item[0].diyframe_frame_id" outlined  depressed color="white"><v-icon left>mdi-playlist-edit</v-icon>EDIT</v-btn>
         <v-btn @click="openFrameURL(item[0].diyframe_frame_id)" outlined depressed  color="white"><v-icon left>mdi-share</v-icon>Link</v-btn>
         <v-spacer></v-spacer>
                  <v-btn v-show="false" @click="del(item[0].diyframe_frame_id)" outlined depressed color="white"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                     </v-card-actions>
  </v-card>

     </v-col>
              </v-row>
               
                 </v-container>
</template>
     
 <!--
  </v-card>
 INSPI
      <div v-if="tab === 'active'">
        <MemoItem
          v-for="memo in getActiveMemo"
          :key="memo.id"
          :id="memo.id"
          :time="memo.time"
          :done="memo.done"
          :memo="memo.memo"
        />
      </div>

     
      <div v-if="tab === 'done'">
        <MemoItem
          v-for="memo in getDoneMemo"
          :key="memo.id"
          :id="memo.id"
          :done="memo.done"
          :time="memo.time"
          :memo="memo.memo"
        />
      </div>
      -->
 
 <!-- #explore -->
            <template v-if="this.$route.path==='/explore'">
             
<!-- TODO container fehlt  -->    

<v-container class="black" fluid height="100%">

   <v-row>
                <v-col
                 cols="12"
                >

  <v-card
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
    
     >
    <v-sheet
dark
  color="black"

>
        <v-card-title primary-title>
  SEARCH
</v-card-title>
                <!-- 
                SEARCH RESULTS FOR: {{  }}
-->
 <v-form>
              
           
                      <!--          prepend-inner-icon="mdi-magnify"  hint="Artist name, token name"   @submit.prevent="doFetch(searchQuery)" -->
                      <v-text-field
                   
                      @keydown.enter.prevent="doFetchSearch(searchQuery)" 
                        v-model="searchQuery"
                        outlined
                        clearable
                        placeholder="tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v"
                        label="Search ꜩ address..."
                        
                        class="pr-2 pl-2"
                        color="grey darken-2"
                      hint="Enter a Tezos address, e.g. tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v"
                     persistent-hint
                  
                      >

                      <!--
                        <template v-slot:prepend>
                          <v-tooltip
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            Search artist name, token ID, token name... 
                          </v-tooltip>
                        </template>
                      -->
                        <!--
                        <template v-slot:append>
                          <v-fade-transition leave-absolute>
                            <v-progress-circular
                              v-if="loading"
                              size="24"
                              color="info"
                              indeterminate
                            ></v-progress-circular>
                           
                      
                              <img
                              v-else
                              width="24"
                              height="24"
                              src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
                              alt=""
                            >
                         
                          </v-fade-transition>

                          
                          <v-btn text class="success  pt-0 mt-0" @click="doFetchSearch(searchQuery)" >SEARCH</v-btn>
                      
                        </template>
 -->
                           <!--
                        <template v-slot:append-outer>
                          <v-menu
                            style="top: -12px"
                            offset-y
                          >
                       
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left>
                                  mdi-menu
                                </v-icon>
                                Options
                              </v-btn>
                            </template>
                       
                            <v-card>
                              <v-card-text class="pa-6">
                                <v-btn
                                  large
                                  text
                                  color="primary"
                                  @click="clickMe"
                                >
                                  <v-icon left>
                                    mdi-target
                                  </v-icon>Click me
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                           
                        </template>
                          -->
                      </v-text-field>
                     <!--   <small>Search for Tezos address, e.g. tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v </small>
                       , token id, title, tag, tezos address, tezos domain...</small> -->
                 
             
              </v-form>
</v-sheet>
    <!-- 
                  <v-card
                   v-if="this.$route.path==='/explore'"
                   class="mx-auto mt-2 rounded-lg"
                   max-width="600"
                   outlined
                   flat
                   
                   >
              height="100h"   !getLoading && arrConvertedID.length >0 &&  <v-subheader>SEARCH RESULTS FOR andre</v-subheader>-->
      
                    <v-list dark three-line color="black">
                    <v-list-item
      v-for="(item, index) in  tokenResult"
                          :key="index"
                                                   :class="[{selected: index % 2 != 0}, {even: index % 2 == 0}]"
                        >

                       <v-list-item-avatar tile class="mr-2" >
 <v-img :src="httpToIPFS(item.thumbnail_uri)" lazy-src="@/assets/v-logo.png" contain width="50" height="50"></v-img>
 </v-list-item-avatar>

 <v-list-item-content>
 <v-list-item-title><span class="font-weight-bold">{{ item.name }}</span></v-list-item-title>

<!-- was das problem? -->
  <v-list-item-subtitle>
<span class="font-weight-bold" v-if="item.artist_profile !== null && item.artist_profile!== undefined">{{getUsername(item.artist_profile.alias,item.artist_address) }}</span>
   </v-list-item-subtitle>

  <v-list-item-subtitle>
 <span class=""> {{ item.platform }}</span> • <span class="font-weight-bold">{{ item.token_id }}</span>
     </v-list-item-subtitle>
 </v-list-item-content>


                            <!-- muss anders für all search 
                                   <v-list-item-subtitle> {{ item.artifact_uri }}</v-list-item-subtitle>
                            <v-list-item-subtitle> {{ item.display_uri}}</v-list-item-subtitle>
                            
                          ERRRO
                            <v-list-item-subtitle > {{ item.artist_profil.alias }}</v-list-item-subtitle>
                       -->
                        
                          <v-list-item-action>
                            <!--  @click.native="dialogAdd =! dialogAdd" #add -->
                                <v-btn
                                @click ="openDialogAddFrame(item.platform,item.token_id)"
                                  color="white"
                                  dark
                                  outlined
                                  depressed
                                                                 >
                                <v-icon color="" left>mdi-plus</v-icon> ADD
                                </v-btn>
                        
                          </v-list-item-action>
                           </v-list-item>
                                          
                    </v-list>
                  </v-card>

                  <!-- via dingens example?! 
                  LOAD MORE (200)
               
                   </v-card>    -->
                </v-col>
              </v-row>
               
                 </v-container>
              </template>


<!-- DIALOGS #d -->
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialogAdd"
            transition="dialog-bottom-transition"
            scrollable
            max-width="450px"
          >
        
            <v-card tile>
              <v-toolbar
            flat
            dark
            color="grey darken-4"
          >
           
           <!-- #add TODO loading adden -->
            <v-toolbar-title class="text-uppercase white--text">Add to frame 
              <v-progress-circular
              v-if="loading"
      class="ml-2"
      color="red"
      indeterminate
    ></v-progress-circular> <!-- Please confirm, Please wait... -->
    </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
              icon
              dark
              @click.native="dialogAdd = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
                     </v-toolbar>

          <template>
            <v-form v-model="valid">
              <v-container class="orange darken-4">
<!-- 
{{ this.toSaveDID }} | {{this.toSavePlatform}} | {{this.toSaveTokenID }}
-->
                <v-row>
                  <v-col
                    cols="12"
                  
                  >

<v-btn @click="handleFormSubmit(toSaveDID)" class="white" color="orange darken-4" outlined depressed dark block large><v-icon  left>mdi-plus</v-icon> CREATE NEW FRAME</v-btn>
<!--
                    <v-text-field
                   dark
                      v-model="firstname"
                      :rules="nameRules"
                      placeholder="Playlist name..."
                      label="Create new playlist"
                      required
                      outlined
                      
                      solo
                      hide-details
                    ></v-text-field>
                    -->
                  </v-col>
          
                 
                </v-row>
              </v-container>
            </v-form>
          </template>


        <v-list two-line>
      <template v-for="(item, index) in getActiveMemo">
    

      
        <v-list-item
        
 @click="addToFrame(item)"
          :key="index"
        >
        
       <v-list-item-avatar>
<v-icon>mdi-checkbox-blank-outline</v-icon>
    </v-list-item-avatar>
    
    <!--
       <v-list-item-avatar tile color="grey darken-1"   size="25">
 <img :src="httpToIPFS(item.thumbnail_uri)">
                          </v-list-item-avatar>
-->
          <v-list-item-content>

<v-list-item-title class=" text-h5  mb-1">
     FRAME <span class="font-weight-bold">{{ item.id }}</span>
     </v-list-item-title>
      

    <v-list-item-subtitle>{{ formatDate(item.time) }}</v-list-item-subtitle>

<!-- TODO alle names rein multiple rein -->


<!--
            <v-list-item-title>Artists <v-chip >{{arrConvertedID.length }}</v-chip>  :  </v-list-item-title>
            -->
    
        <!--     <v-list-item-subtitle>FID: {{ item.id }} </v-list-item-subtitle> 
        
           <v-list-item-subtitle>IDS: {{ item.memo }} </v-list-item-subtitle>  -->
             <!-- <v-list-item-subtitle>CREATED: {{ item.time }} </v-list-item-subtitle>
             <v-icon left small>mdi-calendar-clock-outline</v-icon>
              -->


    
          </v-list-item-content>
         
        </v-list-item>
         <v-divider :key="item.id"></v-divider>
      </template>
    </v-list>

    
                  
            </v-card>
          </v-dialog>
        </v-row>
      </template>

<!-- #edit dialog-bottom-transition -->
  <v-dialog
      v-model="dialogEdit"
       fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >

 <v-card height="100h" flat tile color="black">
 <v-toolbar
            flat
            dark
            color="black"
          >
            <v-btn
              icon
              dark
              @click="dialogEdit = false"
            >
              <v-icon>mdi-close</v-icon> <!-- #current DISCARD changes -->
            </v-btn>

            <v-toolbar-title>EDIT FRAME {{ this.activeFrameID }}      <v-progress-circular
              v-if="loading"
      class="ml-2"
      color="red"
      indeterminate
    ></v-progress-circular></v-toolbar-title>
              <v-spacer></v-spacer>
                        <v-btn
                        @click="handleMemoUpdate()"
                        color="white"
                        outlined
                          dark
                          class="mt-1"                      
                        >
                        <v-icon left >mdi-upload</v-icon>  PUBLISH
                        </v-btn>
            <v-toolbar-items>
                    </v-toolbar-items>
                    </v-toolbar>

    <v-alert
      dense
     
      color="black"
      class="grey--text text--darken-2"
    >
      <v-icon small left color="grey darken-2">mdi-information-outline</v-icon> CLick PUBLISH after editing your frame.
    </v-alert>

 <v-card
  v-for="(item, index) in filteredItems"
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
    :key="index"
     
  >

<!--
<template v-if="Object.entries(filteredItems[index]).length > 1">
  -->

  <v-sheet
dark
  color="black"

>

<v-card
  v-for="(itemX, indexX) in filteredItems[index]"
    class=" mx-auto rounded-lg"
    max-width="600"    
    flat
    outlined
    :key="indexX"
   color="black"
 
      >


<v-list-item three-line >
<!--
<v-list-item-avatar>
          
          {{ indexX }}
      
        </v-list-item-avatar>
-->
 <v-list-item-avatar tile size="75" class="mr-2">
 <v-img :src="httpToIPFS(itemX.thumbnail_uri)"  contain width="75" height="75"></v-img>
 </v-list-item-avatar>

 <v-list-item-content >
 <v-list-item-title><span class="font-weight-bold">{{ itemX.name }}</span> </v-list-item-title>

  <v-list-item-title>
<span v-if="itemX.artist_profile !== null && itemX.artist_profile!== undefined">{{getUsername(itemX.artist_profile.alias,itemX.artist_address) }}</span>
     </v-list-item-title>

       <v-list-item-title>
 <span class="text-capitalize"> {{ itemX.platform }}</span> • <span class="font-weight-bold">{{ itemX.token_id }}</span>
     </v-list-item-title>
 </v-list-item-content>



 <v-list-item-action>
  <!-- TO ADD -->
                <v-list-item-action-text></v-list-item-action-text>
<!-- 
      <v-btn  @click="deleteFrameEntryID(itemX.token_id)" class="mt-4" outlined depressed color="white"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
      -->
            <v-btn v-show="false" @click="deleteFrameEntryID(itemX.token_id)" class="mt-4" outlined depressed color="white"><v-icon>mdi-trash-can-outline</v-icon> (SOON)</v-btn>

              </v-list-item-action>

    </v-list-item>

 <v-divider class=""></v-divider>
 

</v-card>
 </v-sheet>
<!--
</template>
-->
</v-card>
<!-- ADDEN
                <v-icon
             @click="deleteFrameEntryID(this.arrID, convertSingleDID(item[0].token_id,'FXHASH'))"
                 outlined
                  color="grey lighten-1"
                >
                mdi-trash-can-outline
                </v-icon>
-->

         </v-card>
    </v-dialog>


<!-- in main? -->
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
    >
      {{ textSnackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="orange darken-4"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>

<!-- 
 v-show="!getDevice()"
 class="hidden-sm-and-up"
-->

 </v-main>
<v-bottom-navigation
:value="value"
class="hidden-sm-and-up orange--text"
       grow
    
               fixed
        flat


         background-color="black lighten-4"
         dark
          app
    >
          <v-btn :ripple="false" @click="changeURL('HOME')"  to="/">
<span>HOME</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn :ripple="false" @click="changeURL('FRAMES | ' + getActiveMemo.length)" to="/frames">
        <span>FRAMES</span>
        <v-icon>mdi-image-filter-none</v-icon>
      </v-btn>

      <v-btn :ripple="false" @click="changeURL('EXPLORE')" to="/explore">
        <span>EXPLORE</span>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn :ripple="false" @click="changeURL('ABOUT')"  to="/about">
        <span>ABOUT</span>
        <v-icon>mdi-image-filter-center-focus</v-icon>
      </v-btn>
    </v-bottom-navigation>

<!-- /APP -->
</template>
  </v-app>
</template>

<script>
// #import #b
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";
import { formatPkhString } from "./utils";
// import { useRouter } from 'vue-router'
import { format } from 'date-fns'

// import { useRoute } from 'vue2-helpers/vue-router';

// const route = useRoute();
// console.log(route);
    const querySingleTokenID = `
 query SingleTokenID($valID: String = "",$valPlatform: String = "") {
  tokens(where: {token_id: {_eq: $valID}, platform: {_eq: $valPlatform }}) {
    name
    artist_profile {
        alias
      }
      artist_address
      token_id
      platform
       artifact_uri
    display_uri
    thumbnail_uri
    mime_type
  }
}
`;


// store platform + token id ::: f v o t + [id] f_15 split 15 ->dann resolve in frame ->load data ->display data
const queryAllTokensByArtist = `query TokensByArtist($address: String!) {
  tokens(where: {_or: [{royalty_receivers: {receiver_address: {_eq: $address}}}, {artist_address: {_eq: $address}}], platform: {_in: ["FXHASH", "OBJKT", "HEN", "VERSUM"]}}, limit: 100) {
    platform
    token_id
    fa2_address
    artist_address
    artist_profile {
        alias
      }
    name
    description
    updated_at
   
    thumbnail_uri
    mime_type
    royalty_receivers {
      receiver_address
    }
  }
}`;

/* 
 display_uri
    artifact_uri
    artifact_metadata
    */


const ORGquery = `
  query OBJKTBuyers($address: String = "") {
    hic_et_nunc_trade(where: {seller: {address: {_eq: $address}}, token: {creator: {address: {_eq: $address}}}}, distinct_on: buyer_id) {
      buyer {
        address
        purchases_aggregate(where: {seller: {address: {_eq: $address}}}) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

// GEHT  token_id description fxhash mimetype = x gleich //   mime_type
// creator: tz addy, 
const OFFXXquery = `
query SearchTokens {
  tokens(where: {platform: {_eq: "FXHASH"}}, limit: 100) {
    name
    artist_profile {
        alias
      }
    display_uri
    thumbnail_uri
    mime_type
  }
}`;

// tokens(where: {platform: {_in: ["FXHASH", "OBJKT", "HEN", "VERSUM"]}}, limit: 10) {

const OFFqueryAllSearch = `
query SearchAllTokens($queryX: String!) {
  tokens(where: {artist_address: {_eq: $queryX}, token_id: {_eq: $queryX}}, limit: 10) {
    name
    artist_profile {
        alias
      }
      token_id
      description
  }
}`;



// , platform: {_in: ["FXHASH", "OBJKT", "HEN", "VERSUM"]}
const OFFXqueryAllSearch = `query SearchAllTokens($address: String!) {
  tokens(where: {tags: {tag: {_eq: $address}}}) {
artifact_metadata
    artifact_uri
  }
}`


// description , platform: {_in: ["FXHASH", "OBJKT", "HEN", "VERSUM"]}
const OFFXXXqueryAllSearch = `query SearchAllTokens($address: String!) {
  tokens(where: {description: {_eq: $address}}) {
    
artifact_metadata
    artifact_uri
  }
}`



// GEHT
const TEMPOFFquery = `query SearchTokens {
  tokens(where: {holder_address: {_eq: "tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v"}, token: {last_sale_at: {_gte: "2022-01-01", _lt: "2023-01-01"}}}, order_by: {token: {last_sale_at: desc}}) {
    amount
    holder_address
    token {
      fa2_address
      token_id
      editions
      name
      description
      price
      last_sale_at
      last_sales_price
    }
  }
}`;

/*
 { icon: 'mdi-account-box-outline', text: 'Profile' },
        { icon: 'mdi-cog-outline', text: 'Settings' },
        { icon: 'mdi-chat-outline', text: 'Chat' }

        const VBtn = Vue.component('VBtn')
VBtn.options.props.ripple.default = false
        */
// #data #r
export default {
  
   data() {
    return {
      mimeType: 'application',
      loadingInfo: false,
      mouseMoving: false,
      timer: null,
      loadedEntries: false,
            walletAvatar: "",
      walletAddress: "",
      urlUser: "",
      urlFrame: "",
      subMainCounter: 1,
      arrayMain: [],
      objectMain: {},
      arrSub: [],
      arrMemo: [],
      arrIDsingle: [],
     
      counter: 0,
      target: { a: 1, b: 2 },
      toSaveDID: "",
      arrID: [],
      arrXX: [],
      frameArrX: [],
      arrFrame: [],
      arrTokenID: [],
      snackbar: false,
      textSnackbar: "",
      userAddress: "",
      messages: null,
            menuUser: [
       
      ],
      toolbarTitle: "HOME",
      showTooltip: false,
       value: 0,
        active: true,
       selected: [],
      new_memo: "",
          toggling: false,
      deleting: false,
       memo: "",
      arrConvertedID: [],
      activeFrameID: "",
      tab: "active",
      dialogEdit: false,
     explorePage: true,
      toSaveTokenID: '',
      toSavePlatform: '',
 valid: false,
 parisArr: [],
      firstname: '',
      lastname: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
          itemsDialogPlaylist: [
        'Exhibition Paris / Lounge',
        'Private / Living Room / TV',
        'NFT NYC / Room 1 / Display 1',
        'NFT NYC / Room 1 / Display 2',
        'Meetup Paris / Display 1',
      ],
      modeladdToFrame: [''],
      searchQuery:"",
      searchQueryDev:"tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v",
        dialogAdd: false,
      tokenResult: [],
      itemsD: [
        { title: 'Andre Fuchs' },
        { title: 'LewisOsb' },
        { title: 'Pixelart' },
        { title: 'FXHASH' },
      ],
      message: '',
      loading: false,
      cards: ['Today', 'Yesterday'],
      drawer: null,
      links: [
        ['mdi-inbox-arrow-down', 'Inbox'],
        ['mdi-send', 'Send'],
        ['mdi-delete', 'Trash'],
        ['mdi-alert-octagon', 'Spam'],
      ],
      first: '',
      last: 'Doe',
      itemsRow: [
      
        { title: '10' },
        { title: '25' },
        { title: 'ALL' }
      ],
      selection: [1],
      page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      selectedUsers: [],
      editFrame: '8',
    }},
  name: "App",
  components: {
   // Head
  },
  computed: {
    ...mapGetters(["getActiveMemo", "getDoneMemo", "getLoading","getConnected","getPkh"]),

    filteredItems() {
                    return this.arrayMain.filter(item => {
                        return item[0].diyframe_frame_id.indexOf(this.activeFrameID) > -1
                    })
    },

    OFFfilteredItems() {
                    return this.arrayMain.filter(item => {
                        // console.log(item.type.toLowerCase().indexOf(this.search.toLowerCase()))
                        console.log("item[0].diyframe_frame_id ",item[0].diyframe_frame_id);
                       return item[0].diyframe_frame_id.toLowerCase().indexOf(this.editFrame.toLowerCase()) > -1
                       // return item.diyframe_frame_id.indexOf(this.editFrame.toLowerCase()) > -1
                    })
                },
  },
  // #ted
    mounted() {
console.log("DIYFRAME by Andre Fuchs");
console.log("https://DesignerWhoCodes.de");
console.log("🦊️");

console.log("+ mounted");
var isChromium = !!window.chrome;
console.log("? isChromium ", isChromium)

// 1. CREATE AN ARRAY FOR EACH MEMO, console.table(arrTokenID); USB: 

// IDEE: nennen 71 72 73 frameID + MemoID innen
// IST ARRAY!

  this.$nextTick(function() {
       console.log("+ mounted + tick");
//  === '/frame/tz1i4vowP9mHi83KTJikHwB433id3CXGasqU/16'

  if(this.$route.path.indexOf('/frame/tz') !== -1) {
this.getFrameURL()
   this.mouseMove()
} else {
  // RICHTIG oder kann weg? muss rein erstmal
    //  t
    this.userAddress = this.getPkh
}

    // show fullscreen for 6 seconds
 
    })


/*
for (var i = 0; i < this.getActiveMemo.length; i++) {
this.arrTokenID[i].push("MEMO" + i,"NEW");
console.log("______arrTokenID", this.arrTokenID);
}
*/

 //   for (var i = 0; i < this.getActiveMemo.length; i++) {
   // ERST WENN GELADEN
  //  console.log("this.getActiveMemo ",this.getActiveMemo[i]);
  //  this.convertMultipleDID(this.getActiveMemo[i].memo,this.getActiveMemo[i].id)
 //   }
  },
  watch: {
    getConnected(newValue) {
    console.log("🔥🔥🔥🔥🔥🔥🔥🔥⚙️ getConnected");

      if (newValue && this.getPkh !== undefined) {
   
// if(this.$route.path === '/frame/tz1i4vowP9mHi83KTJikHwB433id3CXGasqU/16') {
if(this.$route.path.indexOf('/frame/tz') !== -1) {

 this.userAddress = this.getPkh
 this.getMemoListFrame(this.urlFrame);
} else {
      this.userAddress = this.getPkh
        this.getMemoList();
      
        //
}
//
      }
    },
  },
  created() {
    console.log("+created");
    this.checkWalletConnection();

    if(this.getActiveMemo.length > 0) {
     console.log(":) active memos");
}else {
    console.log(":( no active memos");
  }


  for (var i = 0; i < this.getActiveMemo.length; i++) {
    console.log("__this.getActiveMemo ",this.getActiveMemo.length);
this.convertMemoIDS(this.getActiveMemo[i].memo,this.getActiveMemo[i].id) 
  } 



  },
  // #m
  methods: {
    ...mapActions(["connectionToContract","getMemoListFrame","checkWalletConnection", "getMemoList","addMemo", "toggleMemo","deleteMemo", "toggleMemoStatus","updateMemo","connectWallet", "disconnectWallet"]),
            
            toggleFullScreen() {
      if (document.fullscreenElement) {
        document
          .exitFullscreen()
          .then(() => console.log('Document Exited from Full screen mode'))
          .catch(err => console.error(err))
      } else {
        document.documentElement.requestFullscreen({ navigationUI: 'hide' })
      }
      //
    },
            getFrameURL() {

let urlX = this.$route.path.split("/")
console.log("urlX ",urlX);

     this.urlUser =  urlX[2]
     this.urlFrame = urlX[3]

console.log("this.urlUser ",this.urlUser );
console.log("this.urlFrame ",this.urlFrame );

if(this.urlUser === "" || this.urlFrame === "") {
  console.log(" +++NO FRAME OR USER FOUND in URL+++");
} else {
// ERSTMAL OF schon connected 

if(this.$route.path.indexOf('/frame/tz') !== -1) {
this.connectionToContract(this.urlUser)
console.log(" +++connectionToContract");
}
// this.connectionToContract()
}
// v-if="imagePaths" this.queueNextImage();     
    },
  //
          showLoading() {
      console.log('showLoading')
      this.loadingInfo = true
    },
    hideLoading() {
      console.log('hideLoading')
      this.loadingInfo = false
    },
    showError() {
      console.log('showError')
      this.loadingInfo = false
    },
          mouseMove() {
      this.mouseMoving = true
      //
     //  console.log('this.timer ', this.timer)
      if (this.timer === null) {
        this.timer = setInterval(() => {
          this.mouseMoving = false
          clearInterval(this.timer)
          this.timer = null
          //       console.log('this.timer ', this.timer)
        }, 6000)
        //
      }
    }, 
        convertIDframe(val) {
// console.log("__convertIDframe / memos ", val);

let arrFrameIDS = [] 
try{
    arrFrameIDS = val.split(',')
}
catch(err){
    arrFrameIDS = val
}


for (var i = 0; i < arrFrameIDS.length; i++) {
if(arrFrameIDS[i].charAt(0) === "F") {
this.doFetchSingleTokenIDframe(arrFrameIDS[i].replace('F',''),"FXHASH")
}
}

    },
    // todo  CHECK load batch GRAPH QL
      async  doFetchSingleTokenIDframe(valID, valPlatform) {
        console.log("😅 doFetchSingleTokenIDframe");
  const { errors, data } = await this.fetchGraphQL(querySingleTokenID, "SingleTokenID",{"valID":valID,"valPlatform":valPlatform});
  
  if (errors) {
    console.error(errors);
  }
  // push in array?
  // const result = data.tokens[0]

//  
console.log("data.tokens[0] ",data.tokens[0]);
// 
// convertIDframe(getActiveMemo[0].memo)

// PUSH MIME TYPE + https path
// let conv = this.httpToIPFS(data.tokens[0].thumbnail_uri)

  // console.log("doFetchSingleTokenIDframe result ",result);
 // this.arrConvertedID = result.thumbnail_uri; 
  // 

 // data.tokens[0].thumbnail_uri
 // data.tokens[0].thumbnail_uri
 // #current
this.mimeType = "application" 

// console.log("data.tokens[0].artifact_uri ");
// ! GEHT: 
this.filePath = this.httpToIPFS(data.tokens[0].artifact_uri)
  
  console.log("_____filePath ", this.filePath);
// ! FÜR DEBUG LOADING SCREEN
// this.filePath = this.httpToIPFS(data.tokens[1].thumbnail_uri)
  
  // this.imagePaths.push(data.tokens[0])
    this.loadedEntries = true

// next image
// ENDE
    // title =
    // artist = getusername
    // document.title = "Title by Artist" 
  
}, 
        getRandomInt(max) {
  return Math.floor(Math.random() * max);
},
formatPkhString(pkh) {
  return pkh.substring(0, 5) + "..." + pkh.substring(pkh.length - 4);
},
showSnackbar(val) {
this.snackbar = true
this.textSnackbar= val 

 setTimeout(function() {
      this.snackbar = false
 }, 6000);
},
         getUsername(valAlias,valTezosAddress) {
          // TODO collab

          // return "TEST"
        // console.log("valAlias ", valAlias, valTezosAddress);
if(valAlias === null || valAlias === undefined)  {
          return valTezosAddress
         } else {
          console.log("valAlias length", valAlias );
          return valAlias
         }
         },
         getDevice() {
      // 
      console.log( 'this.$vuetify.breakpoint.name ', this.$vuetify.breakpoint.name)
      if (this.$vuetify.breakpoint.name === 'xs') {
        return false
      }
      return true
    },
    deleteFrameEntryID(id) {
     // id = "1064706" // "1117272"

     // SINGLE UND MULTIPLE UNTERSCHEIDEN - WIE?!

// FÜR MULTIPLE - [1] löscht aber alle
       // const objWithIdIndex = this.arrayMain.findIndex((obj) => obj[1].token_id === id);


/*
items.forEach((item) => item.subItems.forEach((subItem, index) => {
    if (subItem.id === subItemToBeRemovedId) {
        return item.subItems.splice(index, 1);
    }
}));
*/


     // GEHT SINGLE
  const objWithIdIndex = this.arrayMain.findIndex((obj) => obj[2].token_id === id);
  
console.log("objWithIdIndex ",objWithIdIndex);

  if (objWithIdIndex > -1) {
    // auch für multiple machen?
    this.arrayMain.splice(objWithIdIndex, 1);
  }

  // return arr;
},
    OFFdeleteFrameEntryID(valArray,valTokenID) {
// array removeItemOnce //active memo löschen?

console.log("deleteFrameEntryID ", valArray,valTokenID);

console.log("this.arrayMain.indexOf(valTokenID) ",this.arrayMain.indexOf(valTokenID));

 var index = this.arrayMain.indexOf("1117272");

  if (index > -1) {
    this.arrayMain.splice(index, 1);
  }

 
  // return valArray;

//   valArray =  valArray
    },
    logoutUser() {
this.disconnectWallet();
this.$router.push('/#logout')
// TODO reset memos = null
    },
      changeURL(val) {
      // this.$router.push(val)  
window.scrollTo(0, 0)
      },
      async copyToClipboard(mytext) {
          try {
            await navigator.clipboard.writeText(mytext);
          
            this.showTooltip = true
          } catch ($e) {
            alert('Sorry, copy to clipboard is not available on this device');
          }
        },
      formatDate(val) {
return format(new Date(val), 'MMMM dd, yyyy' ) 
//  • HH:mm:ss
      },
         openFrameURL(valFrameID){
         // console.log("valFrameID ",valFrameID);

// if debug

   window.open("localhost:8080/frame/"+this.userAddress+"/"+valFrameID, '_blank')

//   window.open("https://alpha.diyframe.xyz/frame/"+this.userAddress+"/"+valFrameID, '_blank')

      },
      openIPFSLINK(val){
  window.open(httpToIPFS(val), '_blank')
      },
         openLink(val) {
      window.open(val, '_blank')
    },
      async handleFormSubmit(valDID) {
      this.loading = true;
      const payload = valDID //this.memo;
      this.memo = "";
      this.toSaveDID = ""
      await this.addMemo(payload);
      this.dialogAdd = false
      this.loading = false;

// TODO add button open frame oder go to frames
      this.showSnackbar('New Frame Created')
    },
     connect() {
      this.connectWallet();
    },

    disconnect() {
      this.disconnectWallet();
    },

    formatAddress(pkh) {
      return formatPkhString(pkh);
    },
    async handleMemoUpdate() {
      // TODO check if empty aka all entries deleted
      this.loading = true;
      await this.updateMemo({ memo: this.new_memo, id: this.id });
      this.loading = false;
      // this.toggleModal();
    },
    async toggle(){
      this.toggling = true;
      await this.toggleMemoStatus(this.id);
      this.toggling = false;
    },
    async del(val){
      this.deleting = true;
      this.loading = true;
      await this.deleteMemo(val);
      this.deleting = false;
      this.loading = false;
      this.showSnackbar('Frame Deleted')
      // TODO mainArray neu laden
    },
    openDialogAddFrame(valPlatform,valTokenID) {
      if(!this.getConnected) {
         this.connect()
      } else {
        this.dialogAdd = true //! this.dialogAdd
        this.toSavePlatform = valPlatform
        this.toSaveTokenID = valTokenID
        this.toSaveDID = this.convertSingleDID(valTokenID, valPlatform)
        }
      // ADD OBJECT TO ARRAY 1. add memo to array 2. load details add to array 3. publish to blockchain
      },
      // #current  TODO 
      addToFrame(valFrameID) {
        console.log("+addToFrame +valFrameID ",valFrameID);

      let shortPlatform  = this.toSavePlatform.substring(0,1) //.toLowerCase() 
     //  shortPlatform = shortPlatform.toLowerCase()
      let playlistEntry = shortPlatform + this.toSaveTokenID

      if (!this.parisArr.includes(playlistEntry)) {
      this.parisArr.push(playlistEntry);
      } else {
        alert("Already in playlist.")
      }

      console.log(this.parisArr);

      this.dialogAdd =! this.dialogAdd
    
    },
        httpToIPFS(val) {
      // console.log(val)
      if (val) {
        return val.split('ipfs://').join('https://ipfs.io/ipfs/')
      }
     // return '@/assets/logo.png'
 
      },
             convertSingleDID(valTokenID,valPlatform) {
// if(val.charAt(0) === "F") {
  
  // TODO DYN
 return  valTokenID = "F"+valTokenID 
 // }
// const result = 'Hiya how are you'.substring(0,8)
// this.doFetchSingleTokenID()
    },

    convertMemoIDS(val,valFrameID) {
 val = val.split(',')

for (var i = 0; i < val.length; i++) {
    this.doFetchSingleTokenMemoID(val[i],"FXHASH",valFrameID)
    // create array arrID = "eins"
}



// return val
// console.log(" val.split ",  val);

// return this.doFetchSingleTokenMemoID(val,"FXHASH")

 // this.doFetchSingleTokenMemoID(val,"FXHASH")


    },
    //  valPlatform,valFrameID
      async  doFetchSingleTokenMemoID(valID,valPlatform,valFrameID) {

// ENDE: loadingSingleToken = true//
// const diyframeTokenID = { diyframe_token_id: valID };

// REMOVE PLATFORM LETTER
if(valID.charAt(0) === "F") {
valID = valID.replace('F','')
 }

  const { errors, data } = await this.fetchGraphQL(querySingleTokenID, "SingleTokenID",{"valID":valID,"valPlatform":valPlatform});
  if (errors) {
    console.error("_fetch: ", errors);
  } else {
    this.counter+=1
   // console.log("Fetched DIDs: ",this.counter);
  }

// ENDE: loadingSingleToken = false
// 

const diyframeFrameID = { diyframe_frame_id: valFrameID  };

// ENDE: diyframeTokenID, 
//  
const finalObject = Object.assign(diyframeFrameID, data.tokens[0]);


// console.log("*valFrameID ",valFrameID);
// console.log("*valID ",valID);

// if multiple else single
// console.log("this.objectMain[valFrameID] ",this.objectMain[valFrameID]);

if(this.objectMain[valFrameID] === undefined) { 
  // RESET
//  this.subMainCounter = 1

this.objectMain[valFrameID] = {}
this.objectMain[valFrameID][0] = finalObject // data.tokens[0]


// [valID] = data.tokens[0]
} else {
  // += [valID]
// this.objectMain[valFrameID].push(data.tokens[0]) 



// GEHT FAST
/*
let howMany = Object.entries(this.objectMain[valFrameID]).length 
console.log("_____howMany ",howMany);
this.objectMain[valFrameID][howMany] = data.tokens[0]
*/

let howMany =  this.subMainCounter++  // Object.entries(this.objectMain[valFrameID].token_id).length 
console.log("_____howMany ",howMany);
// 
this.objectMain[valFrameID][howMany] = finalObject // data.tokens[0]
// this.objectMain[valFrameID][valID] = data.tokens[0]


// Object.assign(this.objectMain[valFrameID],data.tokens[0])
}


// TODO !!! RESET subMainCounter = 1


// 


// const returned = Object.assign(this.target,data.tokens[0])
// console.log("this.target ",this.target);

// ["fid"]+valFrameID, 

// frame 1_token_id ? + this.getRandomInt(100)
// 
// TEMP OFF 

// const addedID = data.tokens[0]

// IF finalObject push was da ist
// this.arrID.push(finalObject)

// add frame ID dann push dann filter
// TEMP OFF const finalObject = Object.assign(diyframeFrameID, data.tokens[0]);

// if(valFrameID === 8) {
// this.arrIDsingle.push(finalObject)

// pzush in arrIDsingle if 8 da

// }
// MONTAG

/*
if()
this.arrIDsingle.push(finalObject)
else 
this.arrIDsingle.push(finalObject)
*/

// ,[valID]
// length +1

// DONE OKAY (((!!! TODO length counter dynamic machen
if(this.counter === this.getActiveMemo.length) {
console.log("########################### convertMemoIDS ALL DONE ");
// console.log("----", JSON.stringify(this.objectMain, null, '    '))
// GEHT
// console.log("this.objectMain[16] ",this.objectMain[8]);

// CREATE ARRAY 
for (let [key, value] of Object.entries(this.objectMain)) {

 this.arrayMain.push(value);  
// console.log(key, value);
}

// console.log(" this.arrayMain 88888888 ",  this.arrayMain[2][0]);

console.log("arrayMain ", this.arrayMain); 
//  console.log("----------------", JSON.stringify(this.arrayMain, null, '    '))

// this.arrayMain = JSON.stringify(this.arrayMain, null, '    ')
}

// console.log("arrIDsingle ",arrIDsingle);
// console.log("this.target ",this.target);

// console.log("this.arrID ",this.arrID);

// GO
// ASSIGN vorher die fid!

//  console.log("----------------", JSON.stringify(this.arrID, null, '    '))

// GEHT IRGENDWIE const groupByBrand = groupBy('name');


// const groupByBrand = groupBy('diyframe_frame_id');
// this.arrID =  JSON.stringify({frames: groupByBrand(this.arrID) }, null, 2)

// console.log("this.arrID ",this.arrID);
// console.log(JSON.stringify({frames: groupByBrand(this.arrID)}, null, 2));



// return  data.tokens.json();
//   await   }
}, 
       convertMultipleDID(valMemoDIDs,valFrameID) {
        // 1. GET DIDs
let arrDIDS = valMemoDIDs.split(',')
console.log("valMemoDIDs after split: ", arrDIDS);

// 2. CREATE ARRAY WITH CUSTOM MEMO ID
// this.arrTokenID = new Array(["arrTokenID"+arrDIDS]);
// console.log("arrTokenID ",this.arrTokenID);

// var this.arrTokenID = [];
// for (var i = 1; i <= mynumber; i++) arr.push(""+i);

/*
for (var i = 0; i < arrDIDS.length; i++) {
    this.arrTokenID = ["arrTokenID"+i]
}

console.log("arrTokenID ",arrTokenID);
*/

 for (var i = 0; i < arrDIDS.length; i++) {
if(arrDIDS[i].charAt(0) === "F") {
this.doFetchSingleTokenID(arrDIDS[i].replace('F',''),"FXHASH",valFrameID)
 }
// 
 }

// const result = 'Hiya how are you'.substring(0,8)
// this.doFetchSingleTokenID()
    },

      async  doFetchSingleTokenID(valID, valPlatform,valFrameID) {
  const { errors, data } = await this.fetchGraphQL(querySingleTokenID, "SingleTokenID",{"valID":valID,"valPlatform":valPlatform});
  if (errors) {
    console.error(errors);
  }

// const result = data.tokens[0]
  const result = data.tokens
  // console.log("result ",result);
//  console.log("valFrameID ",valFrameID);


// if(this.frameArrX[valFrameID] === undefined) {
// this.frameArrX=  new Array(["frameX"+valFrameID,result])

//  this.arrFrame =
//  this.arrFrame[valFrameID].push(result);
console.log("---------------------------")

 if(this.frameArrX[valFrameID] === undefined) {
this.frameArrX[valFrameID] = new Array(result)

} else {

  // this.frameArrX[valFrameID].push(result);  ASK DISCORD
 this.frameArrX[valFrameID].push(result); 
 console.log("this.frameArrX[valFrameID] ",this.frameArrX[valFrameID]);
 // 
 }

// console.log(" this.frameArrX ",  this.frameArrX[valFrameID]);
// 
 // return   result
}, 
      async fetchGraphQL(operationsDoc, operationName, variables) {
  const result = await fetch(
    "https://api.teztok.com/v1/graphql",
    {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: variables,
        operationName: operationName
      })
    }
  );

  return await result.json();
},

async  doFetchSearch(val) {
 // const { errors, data } = await this.fetchGraphQL(query, "TokensByArtist");
  const { errors, data } = await this.fetchGraphQL(queryAllTokensByArtist, "TokensByArtist", {"address":val});

  if (errors) {
    console.error(errors);
  }
  const result = data.tokens
  console.log({ result })

  this.tokenResult = result

  return result
}, 

async  doFetch(val) {
 // const { errors, data } = await this.fetchGraphQL(query, "TokensByArtist");
  const { errors, data } = await this.fetchGraphQL(query, "TokensByArtist", {"address":val});

  if (errors) {
    console.error(errors);
  }
  const result = data.tokens
  console.log({ result })

  this.tokenResult = result

  return result
}
  },
};
</script>

<style lang="scss" scoped>
.noWordBreak {
  word-break: keep-all;
}
  .selected {
    background-color:  #000000 !important;
  }
  .even {
    background-color: #111111 !important;
  /*   color: #ffffff;
   outline-color:white;
   outline-style: solid; */
  }
</style>
<!--
TODO
    <style>

      body {
        background-color:black;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll; 
      }
      
      body::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      
      </style>
      -->